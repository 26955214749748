#services .card{
    --bs-card-border-color:none;
    border: 0.1px solid #eeefff;
}

#services .card:hover{
    scale: 1.05;
    transition: .5s;
}

#services .card:not(:hover){
    scale: 1;
    transition: .5s;
}

.detalhe-individual-servico{
    margin: 0 auto;
}

.overlay-services{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo preto com 50% de opacidade */
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay-text-services{
    color: #fff3dc;
    font-size: 1.5rem;
    text-align: left;
}

@media (max-width:600px) {
    .overlay-text-services{
        font-size: 1rem;
    }
    
}