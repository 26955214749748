#App #navbar-desktop {
  display: block;
}
#App #navbar-desktop nav{
  display: flex;
  justify-content: space-around!important;
}
#App #navbar-desktop nav ul li a.link-dark{
  color: #fff3dc!important;
  cursor: pointer;
}
#App #navbar-desktop nav ul li a.link-dark:hover{
  transition: .5s;
  color: brown;
}
#App #navbar-mobile {
  display: none;
}
body {
  background-color: #fff!important;
}
#button-whatsapp{
  font-size:40px;
  color: white;
  background-color: rgb(37, 211, 102);
  padding: 10px 20px;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  animation: whatsapp 5s infinite;
  cursor: pointer;
}

@keyframes whatsapp {
  0%{
    scale: 0.95;
  }
  50%{
    scale: 1.1;
  }
  100%{
    scale: 0.95;
  }
}

#button-whatsapp:hover{
  transition: .5s;
  background-color: rgb(27, 151, 73);
}
#button-whatsapp:not(:hover){
  transition: .5s;
  background-color: rgb(37, 211, 102);
}

.titulo-section-light {
  z-index: 9999!important;
  font-size: 4.5em!important;
  margin-bottom: 5px;
  color:#9f1321;
  text-align: left;
}

.btn-warning-light {
  margin-top: 20px;
  background-color: #9f1321 !important;
  font-size: 20px!important;
  font-weight: 500;
  color: white!important;
}
.btn-warning-light:hover {
  margin-top: 20px;
  background-color: #6a121a !important;
  color: white!important;
}
.nav-link {
  cursor: pointer!important;
}
.btn-instagram {
  background-color: #F09433!important;
  border: none!important;
  color: #fff!important;
  padding: 7px 13px!important;
  border-radius: 5px!important;
  text-decoration: none!important;
}

.btn-instagram:hover {
  background-color: #C17A29!important; /* Cor mais escura */
}

.btn-whatsapp {
  background-color: #25D366!important;
  border: none!important;
  color: #fff!important;
  padding: 10px 20px!important;
  border-radius: 5px!important;
  text-decoration: none!important;
}

.btn-whatsapp:hover {
  background-color: #1D9A52!important; /* Cor mais escura */
}

.divisor {
  width: 250px;
  height: 2px;
  background-color: #9f1321;
  transition: .5s;
  margin-bottom: 40px;
}
.cta-projetos {
  font-size:40px;
  text-align:center;
  max-width:800px;
  margin:0 auto;
}

.titulo-metalseg {
  font-size: 80px!important;
}

@media screen and (max-width:992px) {
  .titulo-section-light {
    z-index: 9999!important;
    font-size: 3.3em!important;
    margin-bottom: 5px;
  }
}
@media screen and (max-width:600px) {
  #App #navbar-desktop {
    display: none;
  }
  #App #navbar-mobile {
    display: block;
  }
  
  .titulo-section-light {
    z-index: 9999!important;
    font-size: 3em!important;
    margin-bottom: 5px;
    text-align: center;
  }
  .divisor {
    width: 150px;
    height: 2px;
    background-color: #9f1321;
    margin:0 auto 40px auto;
  }
  .titulo-metalseg {
    font-size: 50px!important;
  }
  .cta-projetos {
    font-size: 20px!important;
}
}