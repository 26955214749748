/* src/components/Header/Header.css */
.header-container {
    position: relative;
    width: 100%;
  }

  .navbar-brand img {
    border-radius: 50%;
  }
  .header-titulo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 2;
    text-align: left;
  }
  
  .header-titulo h1 {
    margin: 0;
    font-size: 4.5rem!important;
    color: #fff3dc;
  }
  .header-titulo h1 span{
    margin: 0;
    font-size: 6rem!important;
    color: #fff3dc;
  }
  .header-titulo p {
    margin: 0;
    font-size: 1.5rem!important;
    color: #d7be91;
  }
  .image-wrapper {
    position: relative;
    width: 100%;
    min-height: 500px;
  }
  
  .header-image {
    width: 100%;
    min-height: 500px;
    display: block;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
}
  
.nav-item {
    font-weight: 500;
}

@media (max-width:1080px) {
      
  .header-titulo h1, .header-titulo h1 span {
    font-size: 4.5rem!important;
  }
  .header-titulo p {
    font-size: 1rem!important;
  }
  .header-titulo {
    margin: 20px 0;
  }
}

@media (max-width:600px) {
      
    .header-titulo h1,  .header-titulo h1 span {
      font-size: 2.5rem!important;
    }
    .header-titulo p {
      font-size: 0.9rem!important;
    }
  }