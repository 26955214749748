#projetos{
    height: 100%;
    background-color:#d6d6d6;
}

.avaliacaosingle{
    width:300px;
}

.cta-projetos {
    font-size:40px;
    text-align:center;
    max-width:800px;
    margin:0 auto;
}

@media screen and(max-width: 600px) {
    


}